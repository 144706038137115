
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, ref, reactive, UnwrapRef, toRefs, onMounted } from 'vue'
import { notification, Modal } from 'ant-design-vue'

import moment from 'moment'

import router from '../../../router'
import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import subscriptionService from '../../../services/subscription'
import groupService from '../../../services/candidateGroup'
import commonServices from '../../../services/common'

import ICandidate from '../../../interface/profile'
import IProfileType from '../../../interface/profileType'
import ICourse from '../../../interface/course'

import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'

interface Accessf {
  email: string;
  send_login: boolean;
  certificate_access: boolean;
}
export default defineComponent({
  components: {
  },
  props: ['clientId', 'groupId'],
  setup (props, { emit }) {
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 14, offset: 0 },
        sm: { span: 12, offset: 6 }
      }
    }
    const fromEmail = ref<string>(process.env.VUE_APP_FROM_EMAIL)
    const fromName = ref<string>(process.env.VUE_APP_FROM_NAME)
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const current = ref<number>(0)
    const showAllCourse = ref<boolean>(true)
    const showGroupCourse = ref<boolean>(false)
    const candidate = ref<ICandidate>()
    const profileType = ref<IProfileType>()
    const assignedCourses = ref<Array<ICourse>>([])
    const unassignedCourses = ref<Array<ICourse>>([])
    const availableCourse = ref([])
    const availableGroupedCourse = ref([])
    const groupCourse = ref([])
    const selectedCourseToDisplay = ref([])
    const availableCredit = ref<number>(0)
    const groupId = ref<string>('')
    const disableCandidateAdd = ref<boolean>(false)
    const subscription = ref()
    const steps = [
      {
        title: 'Details',
        slug: 'details'
      },
      {
        title: 'Courses',
        slug: 'courses'
      },
      {
        title: 'Access',
        slug: 'access'
      }
    ]
    const visible = ref<boolean>(false)
    const candidateFormRef = ref()
    const accessFormRef = ref()
    const candidateRules = validate.clientValidation
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const candidateForm: UnwrapRef<ICandidate> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      profile_type: '',
      certificate_access: false,
      created_by: '',
      is_active: true,
      send_login: true,
      status: 'active',
      user_id: '',
      group: ''
    })
    const accessForm: UnwrapRef<Accessf> = reactive({
      email: '',
      send_login: false,
      certificate_access: false
    })
    const groups = ref()
    const loading = ref<boolean>(false)
    const state = reactive({
      indeterminate: false,
      selectAllSingleCourse: false,
      selectAllGroupCourse: false,
      selectedCourse: [],
      selectedGroup: []
    })
    const onCheckAllSingleCourseChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const onCheckAllGroupCourseChange = (e: any) => {
      const aGroup = []
      for (const group of availableGroupedCourse.value) {
        aGroup.push(group.value)
      }
      Object.assign(state, {
        selectedGroup: e.target.checked ? aGroup : [],
        indeterminate: false
      })
    }
    const listAllCourse = async () => {
      showAllCourse.value = true
      showGroupCourse.value = false
    }
    const listGroupCourse = async () => {
      showGroupCourse.value = true
      showAllCourse.value = false
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getClientId()
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getClientprofileType = async () => {
      const responce = await profileService.getProfileType('candidate')
      profileType.value = responce.data
      candidateForm.profile_type = profileType.value._id
    }
    const getAssignedCourse = async () => {
      try {
        const responce = await courseService.getAssignedCourse(candidate.value._id)
        assignedCourses.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const assignCourse = async () => {
      try {
        const lSubscription = commonServices.getCurrentSubscription()
        const enroll = []
        loading.value = true
        for (const courseId of state.selectedCourse) {
          let expiryDate = ''
          const course = unassignedCourses.value.filter(course => course._id === courseId)
          if (course.length > 0) {
            if (course[0].validity > 0) {
              expiryDate = moment().add(course[0].validity, 'months').format()
            }
          }
          enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: courseId, expiry_date: expiryDate, subscription: lSubscription._id })
        }
        console.log('state', state)
        for (const groupId of state.selectedGroup) {
          const group = groupCourse.value.filter(group => group._id === groupId)
          console.log('group', group)
          if (group.length > 0) {
            for (const course of group[0].courses) {
              const courseDuplicate = enroll.filter(e => e.course === course._id)
              if (courseDuplicate.length === 0) {
                let expiryDate = ''
                if (course.validity > 0) {
                  expiryDate = moment().add(course.validity, 'months').format()
                }
                enroll.push({ enrolled_date: new Date(), profile: candidate.value._id, course: course._id, expiry_date: expiryDate, subscription: lSubscription._id })
              }
            }
          }
        }
        if (lSubscription.type === 'Credit') {
          const subscribedCourses = await subscriptionService.getSubscribedCourses(lSubscription._id)
          const subscribedCourseCount = subscribedCourses.data.length + enroll.length
          console.log(lSubscription.quantity, subscribedCourseCount)
          if (lSubscription.quantity < subscribedCourseCount) {
            loading.value = false
            notify('Warning', 'You dont have enough credit, please update your credit.', 'warning')
            return
          }
          lSubscription.available_credit = (lSubscription.available_credit - enroll.length)
          const responce = await subscriptionService.updateSubscription(lSubscription._id, lSubscription)
          localStorage.subscription = JSON.stringify(responce.data)
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        // closeCourseListModal()
        getAssignedCourse()
        // selectedCourseToDisplay.value = []
        // for (const course of state.selectedCourse) {
        //   selectedCourseToDisplay.value.push(availableCourse.value.filter(c => c.value === course)[0])
        // }
        notify('Success', 'Course Assigned Successfully', 'success')
        router.push(`/client/candidates${loginAs.value}`)
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const sendEmailToCandidate = async () => {
      try {
        // candidate.value.certificate_access = accessForm.certificate_access
        // await profileService.updateProfile(candidate.value._id, candidate.value)
        if (candidateForm.send_login) {
          const data = {
            from: `${fromEmail.value} <${fromName.value}>`,
            to: candidate.value.email,
            subject: 'Your account has been created',
            templateId: 'new-candidate-create',
            content: {
              user_name: candidate.value.email,
              password: candidate.value.password,
              full_name: `${candidate.value.first_name} ${candidate.value.last_name}`
            }
          }
          await profileService.sendEmail(data)
          notify('Success', 'Candidate Account Created Successfully', 'success')
        }
        const profile = commonServices.getCurrentProfile()
        if (profile.profile_type.slug === 'agency') {
          emit('refreshCandidateList', {})
        } else {
          if (props.groupId) {
            router.push(`/client/candidate_group/add/${props.groupId}${loginAs.value}`)
          } else {
            router.push(`/client/candidates${loginAs.value}`)
          }
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const createCandidate = async (values: ICandidate) => {
      try {
        const lsubscription = commonServices.getCurrentSubscription()
        availableCredit.value = +lsubscription.available_credit
        subscription.value = lsubscription
        loading.value = true
        const responce = await profileService.createNewCandidate(values, props.clientId)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          candidate.value = responce.data
          candidateForm.user_id = candidate.value.user_id
          assignCourse()
          if (groupId.value) {
            await groupService.addCandidates({ enabled: true, candidate: candidate.value._id, group: groupId.value })
          }
          // Reduice credit
          if (subscription.value.type === 'Subscription') {
            subscription.value.available_credit = (subscription.value.available_credit - 1)
            const responce = await subscriptionService.updateSubscription(subscription.value._id, subscription.value)
            subscription.value = responce.data
            availableCredit.value = (availableCredit.value - 1)
            localStorage.subscription = JSON.stringify(responce.data)
            if (availableCredit.value <= 0) {
              disableCandidateAdd.value = true
            }
          }
          current.value++
        }
        loading.value = false
      } catch (error) {
        console.log('error', error)
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateCandidate = async (values: ICandidate) => {
      try {
        loading.value = true
        const responce = await profileService.updateProfile(candidate.value._id, values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          candidate.value = responce.data
          current.value++
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getGroupCourses = async () => {
      try {
        availableGroupedCourse.value = []
        const clientId = commonServices.getClientId()
        const responce = await courseService.getGroupCourse(clientId)
        groupCourse.value = responce.data
        for (const group of groupCourse.value) {
          availableGroupedCourse.value.push({ label: group.name, value: group._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        let candidateId = null
        if (candidate.value) {
          candidateId = candidate.value._id
        }
        const responce = await courseService.getCandidateUnAssignedCourse(props.clientId, candidateId)
        unassignedCourses.value = responce.data
        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const unAssignCourse = async (id, index) => {
      try {
        await courseService.unAssignCourse(id)
        assignedCourses.value.splice(index, 1)
        getUnassignedCourses()
        notify('Success', 'Course Unassigned Successfully', 'success')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (id, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        class: 'delte-confirm-modal',
        async onOk () {
          unAssignCourse(id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const next = async () => {
      candidateFormRef.value.validate().then(() => {
        if (candidate.value && candidate.value._id) {
          updateCandidate(candidateForm)
        } else {
          if (candidateForm.group) {
            groupId.value = candidateForm.group
          }
          createCandidate(candidateForm)
        }
      }).catch((error: ValidateErrorEntity<ICandidate>) => {
        console.log('error', error)
      })
    }
    onMounted(() => {
      groupId.value = props.groupId
      const lSubscription = commonServices.getCurrentSubscription()
      availableCredit.value = +lSubscription.available_credit
      subscription.value = lSubscription
      if (subscription.value.type === 'Subscription' && availableCredit.value <= 0) {
        disableCandidateAdd.value = true
      }
      getClientprofileType()
      getGroups()
      getUnassignedCourses()
      getGroupCourses()
    })
    return {
      formItemLayoutWithOutLabel,
      candidateRules,
      candidateFormRef,
      steps,
      current,
      notify,
      candidateForm,
      loading,
      next,
      visible,
      ...toRefs(state),
      accessFormRef,
      accessForm,
      sendEmailToCandidate,
      onCheckAllSingleCourseChange,
      onCheckAllGroupCourseChange,
      availableCourse,
      assignCourse,
      selectedCourseToDisplay,
      disableCandidateAdd,
      showDeleteConfirm,
      getUnassignedCourses,
      getAssignedCourse,
      assignedCourses,
      availableGroupedCourse,
      groups,
      showGroupCourse,
      showAllCourse,
      listAllCourse,
      listGroupCourse
    }
  }
})
